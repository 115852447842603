import React, { useEffect, useRef } from 'react';
import { IcoYoutube, IcoFb, IcoWhatsapp } from './UiKit/SocialIcos';

// Function to handle smooth scrolling
const smoothScrollTo = (targetId) => {
  const target = document.getElementById(targetId);
  if (target) {
    // Update the URL hash without jumping
    window.history.pushState(null, null, `#${targetId}`);

    // Scroll to the target smoothly
    target.scrollIntoView({ behavior: 'smooth' });
  }
};

export const Hero = () => {
  const leafRef1 = useRef(null);
  const leafRef2 = useRef(null);
  const leafRef3 = useRef(null);
  const txt1 = useRef(null);
  const txt2 = useRef(null);
  const txt3 = useRef(null);
  const picIskon = useRef(null);
  const picParthaSarathi = useRef(null);
  const picPrabhupada = useRef(null);
  const menu = useRef(null);
  const handleScroll = () => {
    const scrollY = window.scrollY;

    // Adjust the position of the leaves based on scroll
    if (leafRef1.current) {
      leafRef1.current.style.transform = `translateY(${scrollY * 0.1}px)`; // Adjust the multiplier for effect
    }
    if (leafRef2.current) {
      leafRef2.current.style.transform = `translateY(${scrollY * 0.2}px)`; // Adjust the multiplier for effect
    }
    if (leafRef3.current) {
      leafRef3.current.style.transform = `translateY(${scrollY * 0.1}px)`; // Adjust the multiplier for effect
    }
    if (picParthaSarathi.current) {
      // picParthaSarathi.current.style.transform = `translateY(${scrollY * 0.1}px)`; // Adjust the multiplier for effect
    }
    if (picPrabhupada.current) {
      // picPrabhupada.current.style.transform = `translateY(${scrollY * -0.1}px)`; // Adjust the multiplier for effect
    }
    if (picIskon.current) {
    // picIskon.current.style.transform = `translateY(${scrollY * -0.1}px)`; // Adjust the multiplier for effect
    }
  };

  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Set initial styles for animation
    const animateElements = () => {
      const elements = [
        picPrabhupada,
        picParthaSarathi,
        picIskon,
        txt1,
        txt2,
        txt3,
        menu,
        leafRef1,
        leafRef2,
        leafRef3,
      ];
      elements.forEach((ref, index) => {
        if (ref.current) {
          ref.current.style.opacity = '0';
          ref.current.style.transform = 'translateY(-20px)';
          ref.current.style.transition = 'opacity 0.5s ease, transform 0.5s ease'; // Add transition
        }
      });

      // Trigger animation after a staggered delay
      elements.forEach((ref, index) => {
        setTimeout(() => {
          if (ref.current) {
            ref.current.style.opacity = '1';
            ref.current.style.transform = 'translateY(0)';
          }
        }, 100 + index * 100); // Stagger by 100ms for each element
      });
    };

    animateElements(); // Call the animation function

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="c0">
      <div className="bg bg--home">
        <div className="x_leafs">
          <div className="leaf-container" ref={leafRef1}>
            <div className="x_leaf_r" />
          </div>
          <div className="leaf-container" ref={leafRef2}>
            <div className="x_leaf_l2" />
          </div>
          <div className="leaf-container" ref={leafRef3}>
            <div className="x_leaf_l" />
          </div>
        </div>
      </div>
      <div className="w">
        <div className="x_psdg_hero">
          <div className="x_iskon00" ref={picIskon}>
            <div className="x_psdg_blob2" />
            <div className="x_iskon0">
              <div className="x_iskon_ico" />
            </div>
          </div>
          <div className="x_prabh" ref={picPrabhupada}>
            <div className="x_prabh_title">
              <div className="x_prabh_title_txt">
                <p className="x_prabh_title_h">His Divine Grace AC Bhaktivedanta Swami Prabhupada</p>
                <p>Founder Acarya of the International Society for Krishna Consciousness</p>
                <p>Revered Spiritual Master of His Holiness Partha Sarathi Das Goswami</p>
              </div>
            </div>
          </div>
          <div className="x_psdg_title">
            <div className="x_psdg_pic0">
              <div className="x_psdg_blob" />
              <div className="x_psdg_pic" ref={picParthaSarathi}>
                <div className="x_psdg_flare" />
                <div className="x_psdg_flare x_psdg_flare--1" />
              </div>
            </div>
            <div className="x_psdg_x">
              <div className="x_welcome" ref={txt1}>
                <div>Welcome to the official website of&nbsp;His&nbsp;Holiness</div>
              </div>
              <div className="x_psdg0" ref={txt2}>
                <div>Partha Sarathi Das Goswami</div>
              </div>
              <div className="x_psdg_story" ref={txt3}>
                <p>His Holiness Partha Sarathi Das Goswami, was born in Suffolk, England, 19 November 1952. During his studies, Maharaja had been endowed with the mercy of receiving some of Srila Prabhupada’s books. </p>
                <p>Realizing the fundamental and spiritual significance of these books, Maharaja joined ISKCON, in Bury Place London, 1973. In 1974, by the mercy of the devotees, Maharaja had been invited to South Africa to help propagate the teachings of Lord Caitanya Mahaprabhu.</p>
                <p>HH Partha Sarathi Das Goswami spends most of his time in South Africa, nurturing his many disciples and travels twice a year to Ukraine and India.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w">
        <div className="x_menu0">
          <div className="x_welcome">
            <div>Please Follow Maharaja on social networks</div>
          </div>
          <div className="x_menu_right">
            <div className="x_f_list">
              <a href="https://www.youtube.com/@PSDGCONNECT" target="_blank" className="x_f_social0 w-inline-block">
                <div className="x_f_social_ico">
                  <IcoYoutube />
                </div>
                <div className="x_f_social_name">
                  <div>Youtube</div>
                </div>
              </a>
              <a href="https://www.facebook.com/psdgoswami1" target="_blank" className="x_f_social0 w-inline-block">
                <div className="x_f_social_ico">
                  <IcoFb />
                </div>
                <div className="x_f_social_name">
                  <div>Facebook</div>
                </div>
              </a>

              <a id="w-node-a1564e01-1bca-7524-a0cc-cf0cfc7f7023-62fce811" href="https://chat.whatsapp.com/F75khArsaHq425999xy59F" target="_blank"
                className="x_f_social0 w-inline-block">
                <div className="x_f_social_ico">
                  <IcoWhatsapp />
                </div>
                <div className="x_f_social_name">
                  <div>Whatsapp</div>
                </div>
              </a>

            </div>
          </div>
        </div>
      </div>


      {/* <div className="w" ref={menu}>
        <div className="x_menu0">
          <a
            className="x_menu_item"
            href="#videos"
            onClick={(e) => {
              e.preventDefault(); // Prevent default anchor click behavior
              smoothScrollTo('videos'); // Call the smooth scroll function
            }}
          >
            <div>Videos</div>
          </a>
          <a
            className="x_menu_item"
            href="#contact"
            onClick={(e) => {
              e.preventDefault(); // Prevent default anchor click behavior
              smoothScrollTo('contact'); // Call the smooth scroll function
            }}
          >
            <div>Contact</div>
          </a>
        </div>
      </div> */}
    </div>
  );
};
