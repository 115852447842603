import React, { useEffect, useState } from 'react'
const Context = React.createContext();
var Airtable = require('airtable');


function Provider({ children }) {

  const AIRTABLE_URL = process.env.REACT_APP_AIRTABLE_URL;
  const AIRTABLE_APIKEY = process.env.REACT_APP_AIRTABLE_APIKEY;
  var base = new Airtable({ apiKey: AIRTABLE_APIKEY }).base(AIRTABLE_URL);


  const [ready, setReady] = useState(false)
  const [language, setLanguage] = useState('en')

  const [view, setView] = useState('g')

  const [search, setSearch] = useState('')
  const [filterVenue, setFilterVenue] = useState('')
  const [filterYear, setFilterYear] = useState('')


  const [videos, setVideos] = useState([])
  const [venues, setVenues] = useState([])
  const [videoCategories, setVideoCategories] = useState([])


  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const categoriesResponse = await fetch('/data/videoCategories.json');
  //       const venuesResponse = await fetch('/data/venues.json');
  //       const videosResponse = await fetch('/data/videos.json');

  //       if (!categoriesResponse.ok || !venuesResponse.ok || !videosResponse.ok) {
  //         throw new Error('Failed to fetch data');
  //       }

  //       const categoriesData = await categoriesResponse.json();
  //       const venuesData = await venuesResponse.json();
  //       const videosData = await videosResponse.json();

  //       setVideoCategories(categoriesData);
  //       setVenues(venuesData);
  //       setVideos(videosData);
  //       setReady(true); // Set ready to true after data is loaded
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);



  useEffect(() => {
    const fetchVideoCategories = async () => {
      try {
        const records = await base('videoCategories').select().all();
        const results = records
          .filter(one => one.get('active'))
          .filter(one => one.get('videos'))
          .map(one => ({
            '_id': one.id,
            'order': one.get('order'),
            'id': one.get('id'),
            'title-en': one.get('title-en'),
            'title-ru': one.get('title-ru'),
          }))
          .sort((a, b) => b.order - a.order)

        setVideoCategories(results)
      } catch (error) {
        console.error('Error fetching records:', error);
      }

    }
    if (Object.keys(videoCategories).length === 0) {
      fetchVideoCategories();
    }
  }, []);



  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const records = await base('venues').select().all();
        const results = records
          .filter(one => one.get('active'))
          .filter(one => one.get('videos').length)
          .map(one => ({
            '_id': one.id,
            'id': one.get('id'),
            'title': one.get('title'),
            'sub_title': one.get('sub_title'),
            'videos': one.get('videos') ? one.get('videos') : [],
          }))
          .sort((a, b) => b.videos.length - a.videos.length)

        setVenues(results)
      } catch (error) {
        console.error('Error fetching records:', error);
      }
    }
    if (Object.keys(venues).length === 0) {
      fetchVenues();
    }
  }, []);





  useEffect(() => {


    const fetchVideos = async () => {
      try {
        const records = await base('videos').select().all();
        const results = records
          .filter(one => one.get('active'))
          .filter(one => one.get('youtubeId'))
          .map(one => ({
            '_id': one.id,
            'id': one.get('id'),
            'order': one.get('order'),
            'youtubeId': one.get('youtubeId') ? one.get('youtubeId') : '',
            'language': one.get('language'),
            'title': one.get('id'),
            'start': one.get('start') ? one.get('start') : 0,
            'startTime': one.get('start_time') ? one.get('start_time') : 0,
            'endTime': one.get('end_time') ? one.get('end_time') : null,
            'categories': one.get('categories') ? one.get('categories') : [],
            'venue_id': one.get('venue_id') ? one.get('venue_id') : [],
            'venue_title': one.get('venue_title') ? one.get('venue_title')[0] : '',
            'venue_sub_title': one.get('venue_sub_title') ? one.get('venue_sub_title')[0] : '',
            'date': one.get('date') ? one.get('date') : '',
          }))
          .sort((a, b) => b.order - a.order)

        console.log('results = ', results);
        if (typeof results === 'object' && results.length > 0) setVideos(results)
      } catch (error) {
        console.error('Error fetching records:', error);
      }
    };

    if (Object.keys(videos).length === 0) {
      fetchVideos();
    }



  }, []);




  const getVideoById = (_id) => {
    let vid = videos.filter(one => one._id === _id)[0];
    if (vid) {
      vid['title'] = vid[`title-${language}`];
    } else {
      vid = {}
    }
    return vid;
  }

  const getVenueById = (id) => {
    let ven = venues.filter(one => one.id === id)[0];
    if (ven) {
      ven = ven;
    } else {
      ven = {}
    }
    return ven;
  }


  const filterLang = (arr) => {
    return arr.filter(one => one.language.includes(language))
  }

  const filterContent = (arr, { fv = filterVenue, fy = filterYear, s = search } = {}) => {
    let a = arr
    if (fv) {
      a = a.filter(one => one.venue_id.includes(fv))
        .sort((a, b) => b.order - a.order)
    }
    if (fy) {
      a = a.filter(one => one.date.split('-')[0] === fy)
        .sort((a, b) => b.order - a.order)
    }
    if (s) {
      a = a.filter(one => {
        let a = one.title.toLowerCase().search(typeof s === 'string'
          ? s.toLowerCase()
          : '')
        return a > -1
      })
        .sort((a, b) => b.order - a.order)
    }

    return a
  }



  return (<>
    <Context.Provider
      value={{
        state: {
          language,
          ready,
          filterVenue,
          filterYear,
          search,
          view,
        },
        videoCategories,
        videos,
        venues,
        x: {
          filterContent,
          filterLang,
          getVideoById,
          getVenueById,
          setLanguage,
          setFilterVenue,
          setFilterYear,
          setSearch,
          setView,
        },
      }}
    >
      {children}
    </Context.Provider>
  </>
  )

}

const Consumer = Context.Consumer;

export { Context, Provider, Consumer };
