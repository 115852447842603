import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate, useSearchParams, useParams, useLocation } from 'react-router-dom';
import { Button, Input, Select } from "antd";
import { Context } from '../context';
import { SearchOutlined } from '@ant-design/icons';


export function Filters(props) {
  const context = { ...useContext(Context) };
  const { children } = { ...props };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();

  const handleVenue = (value) => {
    console.log(value);
    context.x.setFilterVenue(value)
    const v = context.x.filterContent(context.videos, { fv: value, fy: context.state.filterYear })
    if (!v.length) {
      context.x.setFilterYear()
    }
    setMenu(false)
  };
  const handleYear = (value) => {
    // console.log(value);
    context.x.setFilterYear(value)
    const v = context.x.filterContent(context.videos, { fy: value, fv: context.state.filterVenue })
    if (!v.length) {
      context.x.setFilterVenue()
    }
    setMenu(false)
  };
  const reset = () => {
    handleVenue({ value: '' })
    handleYear({ value: '' })
    context.x.setSearch('')
    setMenu(false)
  };

  const [menu, setMenu] = useState(false)

  return (
    <>


      <a href='#videos' className="x_mob_menu"
        onClick={() => setMenu(true)}
      >
        <div className="_ico" style={{ fontSize: '2em' }}>search</div>
      </a>
      {menu && <div className="x_mob_z" onClick={() => setMenu(false)} />}

      <div className={`x_v_search ${menu ? 'active' : ''}`}>

        <div className="x_menu ">

          {Boolean(context.videos && context.videos.length) && <>


            {/* <div className="x_menu_line">
              <div className="x_search_input0">
                <Input
                  style={{
                    minWidth: '12em',
                    width: 'auto',
                  }}
                  className="x_search_input0"
                  placeholder='Search by name'
                  prefix={<SearchOutlined />}
                  allowClear
                  value={context.state.search}
                  onChange={(e) => context.x.setSearch(e.target.value)}
                />
              </div>
            </div> */}


            <div className="x_menu_line x_menu_line--scroll" >
              <div className="x_menu_sub">

                <div className="x_menu_sub_h ">
                  <div className="x_menu_sub_h1">Venues</div>
                  {context.state.filterVenue && <div className="x_button active" onClick={() => handleVenue('')}>
                    <div className="_ico">
                      clear
                    </div>
                  </div>}
                </div>

                {context.venues.map(one => {
                  return <a href="#videos" className={`x_menu_sub_li ${context.state.filterVenue === one.id ? 'active' : ''}`}
                    key={`${one.id}-${one.title}`}
                    onClick={() => handleVenue(one.id)}
                  >
                    <div className="x_menu_sub_text">
                      <div className="x_menu_sub_title">{one.title}</div>
                      <div className="x_menu_sub_sub">{one.sub_title}</div>
                    </div>
                    <div className="x_menu_sub_counter">{one.videos.length}</div>
                  </a>
                })}

              </div>
            </div>

            <div className="x_menu_line">
              <div className="x_menu_sub x_menu_sub--y">

                <div className="x_menu_sub_h">
                  <div className="x_menu_sub_h1">Years</div>
                  {context.state.filterYear && <div className="x_button active" onClick={() => handleYear('')}>
                    <div className="_ico">clear</div>
                  </div>}
                </div>


                {[...new Set(context.videos.map(one => one.date.split('-')[0]))]
                  .sort((a, b) => b - a)
                  .map(one => {
                    return <a href="#videos" className={`x_menu_sub_li x_menu_sub_li--y ${context.state.filterYear === one ? 'active' : ''}`}
                      onClick={() => handleYear(one)}
                      key={`year-${one}`}>
                      <div className="x_menu_sub_text">
                        <div className="x_menu_sub_title">{one}</div>
                      </div>
                      <div className="x_menu_sub_counter">{context.videos.filter(v => v.date.split('-')[0] === one).length}</div>
                    </a>
                  })}



              </div>
            </div>




          </>}

          {/* <Button type={'secondary'} onClick={() => reset()}>Clear<span className='mobile_v_no'> filters</span></Button> */}





        </div>



      </div>






    </>
  )
}



export function FiltersSelect(props) {
  const context = { ...useContext(Context) };
  const { children } = { ...props };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState();

  const handleVenue = (value) => {
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
    context.x.setFilterVenue(value.value)
  };
  const handleYear = (value) => {
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
    context.x.setFilterYear(value.value)
  };
  const reset = () => {
    handleVenue({ value: '' })
    handleYear({ value: '' })
    context.x.setSearch('')
  };

  return (
    <>

      <div className="x_v_search_cols ">

        {Boolean(context.videos && context.videos.length) && <>

          <Input
            style={{
              minWidth: '12em',
              width: 'auto',
            }}
            placeholder='Search by name'
            prefix={<SearchOutlined />}
            allowClear
            value={context.state.search}
            onChange={(e) => context.x.setSearch(e.target.value)}
          />


          <Select
            labelInValue
            value={{ value: context.state.filterYear }}
            defaultValue={{
              value: '',
              label: <div className="_f _fgap02">
                <b>All venues</b>
                <div className="_fr _f100">{context.videos.length}</div>
              </div>
            }}
            style={{
              width: '12em',
            }}
            onChange={handleYear}
            options={[{
              value: '',
              label: <div className="_f _fgap02">
                <b>All years</b>
                <div className="_fr _f100">{context.videos.length}</div>
              </div>
            },
            ...[...new Set(context.videos.map(one => one.date.split('-')[0]))]
              .sort((a, b) => b - a)
              .map(one => ({
                value: one,
                label: <div className="_f _fgap02">
                  <b>{one}</b>
                  <div className="_fr _f100">{context.videos.filter(v => v.date.split('-')[0] === one).length}</div>
                </div>
              }))
            ]}
          />



          {context.videos && <Select
          labelInValue
          value={{ value: context.state.filterVenue }}
          defaultValue={{
            value: '',
            label: <div className="_f _fgap02">
              <b>All venues</b>
              <div className="_fr _f100">{context.videos.length}</div>
            </div>
          }}
          style={{
            minWidth: '15em',
            maxWidth: '40em',
            width: '100%',
          }}
            onChange={handleVenue}
          options={[{
            value: '',
            label: <div className="_f _fgap02">
              <b>All venues</b>
              <div className="_fr _f100">{context.videos.length}</div>
            </div>
          },
          ...context.venues.map(one => ({
            value: one.id,
            label: <>
              <div className="_f _fgap02">
                <b>{one.title}</b>
                <div className="mobile_v_no">{one.sub_title}</div>
                {one.videos && <div className="_fr _f100">{one.videos.length}</div>}
              </div>
            </>,
          }))
          ]}
          />}


        </>}

        <Button type={'secondary'} onClick={() => reset()}>Clear<span className='mobile_v_no'> filters</span></Button>



        </div>






    </>
  )
}