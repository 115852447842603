import React from 'react';
import { IcoYoutube, IcoFb, IcoWhatsapp } from './components/UiKit/SocialIcos';

export const Footer = () => {
  return (
    <div className="f" id="contact">
      <div className="w">
        <div className="x_cont0">
          <a href="https://maps.app.goo.gl/1XXwDWQmyZSZibUb8" target="_blank" className="x_cont_map w-inline-block" />
          <div className="x_contacts">
            <h1 className="x_contacts_h">Contacts</h1>
            <div className="x_contacts_line">
              <div className="x_contacts_line_txt">
                <div className="x_contacts_line_txt_title">
                  <img src="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/6729700a41b7f1ade790a769_ico_address.svg" loading="lazy" width={16} height={16} className="x_contacts_line_ico" />
                  <div>
                    <strong>Address</strong>
                  </div>
                </div>
                <p>
                  <a href="https://www.google.com/maps?sca_esv=ca2c68be96a7cdb5&rlz=1C5GCCM_en&output=search&q=50+Bhaktivedanta+Swami+Circle,+Unit+5,+50+RK+Khan+Cir,+Westcliff,+Chatsworth,+4092&source=lnms&fbs=AEQNm0Aa4sjWe7Rqy32pFwRj0UkW1DRbm01j6DCVS0r1sTxn7lWrPPt54xvTeoI9jYBmanbcnj_OgzaZVVS-VpZSx-qF5Ty48PrXY2_X99PvPX5emHfq9VuWcToZcT2Mn2fvwtdnmp0CtlDOx2jathnl0MLuBXQTdTIQVRVJfbkXT_RonXUy-DK29W05W0T7KP63Oy_0YgV3JA6zbkYaeM010VWLn-Fl7g&entry=mc&ved=1t:200715&ictx=111" target="_blank">50 Bhaktivedanta Swami Circle, Unit 5, 50 RK Khan Cir, Westcliff, Chatsworth, 4092</a>
                </p>
              </div>
            </div>
            <div className="x_contacts_line">
              <div className="x_contacts_line_txt">
                <div className="x_contacts_line_txt_title">
                  <img src="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/6729700a49c1bdf40ee453fa_ico_email.svg" loading="lazy" width={16} height={16} className="x_contacts_line_ico" />
                  <div>
                    <strong>E-mail</strong>
                  </div>
                </div>
                <p>
                  <a href="mailto:psdgconnect@gmail.com">psdgconnect@gmail.com</a>
                </p>
              </div>
            </div>
            <div className="x_contacts_line">
              <div className="x_contacts_line_txt">
                <div className="x_contacts_line_txt_title">
                  <img src="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/672bd69766a60803e73eb5ed_social.png" loading="lazy" width={20} height={20} className="x_contacts_line_ico" />
                  <div>
                    <strong>Social networks</strong>
                  </div>
                </div>
                <div className="x_f_list x_f_list--f">
                  <a href="https://www.youtube.com/@PSDGCONNECT" target="_blank" className="x_f_social0 w-inline-block">
                    <div className="x_f_social_ico">
                      <div className="x_f_social_ico w-embed">
                        <IcoYoutube />
            </div>
                    </div>
                    <div className="x_f_social_name">
                      <div>Youtube</div>
                    </div>
                  </a>
                  <a href="https://www.facebook.com/psdgoswami1" target="_blank" className="x_f_social0 w-inline-block">
                    <div className="x_f_social_ico">
                      <div className="x_f_social_ico w-embed">
                        <IcoFb />
                      </div>
                    </div>
                    <div className="x_f_social_name">
                      <div>Facebook</div>
                    </div>
                  </a>
                  <a id="w-node-a1564e01-1bca-7524-a0cc-cf0cfc7f7023-62fce811" href="https://chat.whatsapp.com/F75khArsaHq425999xy59F" target="_blank" className="x_f_social0 w-inline-block">
                    <div className="x_f_social_ico">
                      <div className="x_f_social_ico w-embed">
                        <IcoWhatsapp />
                      </div>
                    </div>
                    <div className="x_f_social_name">
                      <div>Whatsapp</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <a href="https://chat.whatsapp.com/F75khArsaHq425999xy59F" target="_blank" className="x_contacts_whatsapp w-inline-block">
            <img src="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/6735326d97289d6880ba3f59_psdg_whatsapp.jpeg" loading="lazy" width="295.5" sizes="(max-width: 991px) 100vw, 295.5px" srcSet="https://cdn.prod.website-files.com/66da39761f404aa0641056e6/6735326d97289d6880ba3f59_psdg_whatsapp-p-500.jpeg 500w, https://cdn.prod.website-files.com/66da39761f404aa0641056e6/6735326d97289d6880ba3f59_psdg_whatsapp.jpeg 591w" />
          </a>

        </div>
      </div>
      <div className="w">
        <div className="f_c">
          <div className="f_isk">
            <div className="x_iskon0 x_iskon0--f">
              <div className="x_iskon_ico x_iskon_ico--f" />
            </div>
            <div>
              <div>Copyright © 2024 psdgoswami.com</div>
            </div>
          </div>
          <div className="f_kt">
            <div className="_fv _fgap0 _fcc">
              <div className="_f">
                Found a mistake? <b><a href="https://wa.me/17472348328" target="_blank">Please report your feedback</a></b>
              </div>
              <div className="_fm _fgap02">
                Made by <a href="https://krishna.team" target="_blank"> <img src="https://cdn.prod.website-files.com/672951d056df0c9641f8dc94/672953acfda8ed79f8e53efd_chakra-p-500.png" loading="lazy" style={{ width: '1.1em', height: '1.1em', position: 'relative', marginTop: '-0.2em' }} /> Krishna.Team</a>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="x_leafs">
        <div className="x_leaf_r" />
        <div className="x_leaf_l2" />
        <div className="x_leaf_l" />
      </div>
    </div>
  );
};